"use strict";

// import FormFunc from "./form";
import Alpine from 'alpinejs';

window.Alpine = Alpine;
Alpine.start();


//********* Image animation *********/
if (document.querySelector('[data-id="content"]')) {
const body = document.body;
const phone = document.querySelector('[data-id="phone"]');
const logo = document.querySelector('[data-id="logo"]');
const trade = document.querySelector('[data-id="trade"]');

// Variable for window width
let innerWidth = 0;

// Variable for current mouse location
const mouseLocation = {
  x: 0,
  y: 0,
}

// Check if client width is < 768px
const updateSize = () => {
  innerWidth = body.clientWidth;

  // Update body class
  innerWidth > 768 ? 
  body.classList.add('desktop') : 
  body.classList.contains('desktop') &&
  body.classList.remove('desktop');
};

// Window event listener to update size + initial mouse location
// Run it on initial load
updateSize();

// Event listener
window.addEventListener("resize", updateSize);

// Throttle
const throttle = (func, delay) => {
  // Last time the function was run
  let prev = 0;
  return (...args) => {
    // Set new time
    let now = new Date().getTime();
    // New - Old > delay -> run function
    if (now - prev > delay) {
      prev = now;
      return func(...args);
    }
  }
}


// Mousemove function
// Add delay after each mouse movement -> after delay grab x / y values and add 1-2rem to the transform
// Smooth it out
const onMouseMove = (e) => {
    mouseLocation.x = e.clientX;
    mouseLocation.y = e.clientY;
    // Calculation to get the percentage of the mouse location
    const x = (mouseLocation.x / innerWidth).toFixed(2);
    const y = (mouseLocation.y / innerWidth).toFixed(2);
  
    const transformString = (val) => `translate(${x * val}px, ${y * val}px)`;
    

    logo.style.transform = transformString(16);
    phone.style.transform = transformString(24);
    trade.style.transform = transformString(32);
}

const onScroll = () => {
  const scroll = (window.scrollY / innerWidth).toFixed(2);
  const transformString = (val) => `translateY(${scroll * val}px)`;

  logo.style.transform = transformString(10);
  phone.style.transform = transformString(18);
  trade.style.transform = transformString(24);
}

// Image event listener
if (body.classList.contains('desktop')) {
  window.addEventListener("mousemove", throttle(onMouseMove, 66));
} else {
  window.addEventListener("scroll", throttle(onScroll, 66));
}

//********* Form *********/
// FormFunc();
}